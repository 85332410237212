<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<Head></Head>
			<div class="content">
				<div class="wrapper">
					<div class="swipe">
						<el-carousel height="350px">
							<el-carousel-item v-for="item in swipe" :key="item.id">
								<img style="width: 100%;" height="350px" :src="item.url" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="product_title">
						<img style="width: 100%;" src="../assets/show.png" alt="">
					</div>
					<div class="footer">
						<Foot></Foot>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [
					{
						"id": 1,
						"url": "https://quanchengtandian.oss-cn-beijing.aliyuncs.com/uploads/20231217/9777c8f1772111572970e69d91a07318.png",
					},
					{
						"id": 2,
						"url": "https://quanchengtandian.oss-cn-beijing.aliyuncs.com/uploads/20231217/f6c925bc2ad465b56c9ffc839645f434.png",
					},

				],
				titleData: [
				

				]
			};
		},
		methods: {
			// 引入封装好的工具类
			getSwipeData() {
				get("/index/carousel/findAll").then((res) => {
					this.swipe = res.data;
				});
			},
			gettitleData() {
				get('/index/article/findCategoryArticles').then(res => {
					this.titleData = res.data;
				})
			}
		},
		created() {
			// this.getSwipeData();
			// this.gettitleData()
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {
			width: 85%;

			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}
	}

	.footer {

		margin-top: 40px;
		height: 200px;
		// background-color: #528c38;
	}
</style>